const Status = {
    PURCHASE_ORDER_PROBLEM: 'c5ac1758-edc1-11e0-ac38-e811324a6a81',
    PURCHASE_ORDER_FOLLOW_UP: 'a31ff30c-954c-11e1-bf6a-1231392238ea',
    PURCHASE_ORDER_CREATED: '888e165d-ce8f-4977-bbe3-d8364937b127',
    PURCHASE_ORDER_SUBMITTED: 'a4cb57e2-edc1-11e0-ac38-e811324a6a81',
    PURCHASE_ORDER_CONFIRMED: 'b5eb2e8a-edc1-11e0-ac38-e811324a6a81',
    PURCHASE_ORDER_SHIPPED: '08d4a0aa-edc6-11e0-ac38-e811324a6a81',
    PURCHASE_ORDER_READY_FOR_BILLING: 'adbe0dd6-ee11-11e0-ac38-e811324a6a81',
    PURCHASE_ORDER_CLOSED: 'e964a285-b34a-11e2-90f5-e811324a6a81',
    PURCHASE_ORDER_BILLED: '33eabdd4-bbfc-48e0-8010-b59ddf620889',

    SALES_ORDER_NEW: '4c9d3727-a5cb-4d62-ab42-82ae9b5238fb',
    SALES_ORDER_PENDING_CLIENT_APPROVAL: '07f49459-99d7-40c2-ba68-7066a11d76cc',
    SALES_ORDER_CLIENT_CHANGE_REQUESTED: '53b40d53-3dda-4e7e-8a64-6f44c136f96c',
    SALES_ORDER_CLIENT_APPROVED: '16ee0574-75ae-47ca-b2c7-d441bc8f19a1',
    SALES_ORDER_IN_PRODUCTION: '8224599d-d6d6-40b4-be96-84403ea6b2ee',
    SALES_ORDER_SHIPPED: '3d1be487-7195-41b6-8c57-82e4663c36d2',
    SALES_ORDER_READY_TO_BE_INVOICED: '0328ee19-22a8-4774-a75a-75fd66f08044',
    SALES_ORDER_INVOICED: '1e4731d9-85f6-47b1-bc1b-b03be041ed85',
    SALES_ORDER_CLOSED: 'b05b061a-2463-11e1-83be-1231392503bd',

    INVOICE_OPEN: '11a8be26-95a3-4572-ac02-798d5b52f246',
    INVOICE_SENT_TO_CLIENT: '6c41260f-df3a-11eb-8989-0a51afecc89b',
    INVOICE_EXPORTED: '413824bd-85b2-4e69-87b5-72ce1d9964c0',
    INVOICE_PAID: '502140ba-f44e-447d-ae7a-2889492872d7',

    CREDIT_MEMO_OPEN: '375206a3-7cf2-11ef-8427-0ad1674602e7',
    CREDIT_MEMO_EXPORTED: '37a492e6-7cf2-11ef-8427-0ad1674602e7',

    VENDOR_CREDIT_OPEN: '36093b20-7cf2-11ef-8427-0ad1674602e7',
    VENDOR_CREDIT_EXPORTED: '36ba6af0-7cf2-11ef-8427-0ad1674602e7',
};

export default Status;
